<script setup lang="ts">
import {TooltipArrow, TooltipContent, TooltipPortal, TooltipRoot, TooltipTrigger} from "radix-vue";
import {breakpointsTailwind, useBreakpoints} from "@vueuse/core";

const breakpoints = useBreakpoints(breakpointsTailwind);

const isMobile = breakpoints.smaller('md'); // md and smaller

const open = ref<boolean | undefined>(undefined);

function handleClick(e: MouseEvent) {
  if (!isMobile.value) {
    return;
  }

  if (open.value === undefined) {
    open.value = true;
    return;
  }

  open.value = !open.value;
}
</script>

<template>
  <TooltipRoot :open="open">
    <TooltipTrigger as-child @click="handleClick">
      <slot name="trigger"/>
    </TooltipTrigger>
    <TooltipPortal>
      <TooltipContent
          class="tooltip-content"
          :side-offset="5"
      >
        <slot name="default"/>
        <TooltipArrow class="tooltip-arrow" :width="8"/>
      </TooltipContent>
    </TooltipPortal>
  </TooltipRoot>
</template>

<style>
.tooltip-content {
  color: white;
  border-radius: .5ch;
  font-size: .75rem;
  line-height: 1rem;
  min-width: 3rem;
  padding: 1ch 1.5ch;
  text-align: center;
  white-space: pre;
  z-index: 1000;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  background: var(--Fon__Snack);

}

.tooltip-arrow {
  fill: var(--tooltip-bgc);
}
</style>